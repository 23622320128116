// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("/opt/build/repo/src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-guarantee-js": () => import("/opt/build/repo/src/pages/guarantee.js" /* webpackChunkName: "component---src-pages-guarantee-js" */),
  "component---src-pages-how-it-works-js": () => import("/opt/build/repo/src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maintainer-plan-js": () => import("/opt/build/repo/src/pages/maintainer-plan.js" /* webpackChunkName: "component---src-pages-maintainer-plan-js" */),
  "component---src-pages-partners-js": () => import("/opt/build/repo/src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-press-js": () => import("/opt/build/repo/src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-pricing-js": () => import("/opt/build/repo/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-returns-and-refunds-js": () => import("/opt/build/repo/src/pages/returns-and-refunds.js" /* webpackChunkName: "component---src-pages-returns-and-refunds-js" */),
  "component---src-pages-reviews-js": () => import("/opt/build/repo/src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-shipping-js": () => import("/opt/build/repo/src/pages/shipping.js" /* webpackChunkName: "component---src-pages-shipping-js" */),
  "component---src-pages-teeth-straightening-comparator-js": () => import("/opt/build/repo/src/pages/teeth-straightening-comparator.js" /* webpackChunkName: "component---src-pages-teeth-straightening-comparator-js" */),
  "component---src-pages-teeth-whitening-upgrade-js": () => import("/opt/build/repo/src/pages/teeth-whitening-upgrade.js" /* webpackChunkName: "component---src-pages-teeth-whitening-upgrade-js" */),
  "component---src-pages-terms-of-use-js": () => import("/opt/build/repo/src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-what-sets-us-apart-js": () => import("/opt/build/repo/src/pages/what-sets-us-apart.js" /* webpackChunkName: "component---src-pages-what-sets-us-apart-js" */)
}

